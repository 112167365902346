<template>
  <div class="pin-create">
    <div></div>
  </div>
</template>

<script>
import modals from '../components/modals';
import API from '../components/api';
import Pins from '../components/Pins.vue';
import Extractors from '../components/extractors/extractors';

export default {
  name: 'PinCreateFromURL',
  data() {
    return {
      meta: {
        url: null,
        referer: null,
        description: null,
      },
      user: {
        loggedIn: false,
        meta: null,
      },
    };
  },
  methods: {
    initialize(force = false) {
      const self = this;
      API.User.fetchUserInfo(force).then(
        (user) => {
          if (user === null) {
            self.user.loggedIn = false;
            self.user.meta = {};
            modals.openLogin(self, () => null);
          } else {
            self.user.meta = user;
            self.user.loggedIn = true;
            self.createPin();
          }
        },
      );
    },
    onClosed() {
      window.close();
    },
    onCreated() {
      window.close();
    },
    onUpdated() {
      window.close();
    },
    createPin() {
      // start extractors
      const { searchMeta, replaceMeta } = Extractors.extractor(this.meta);
      this.meta.url = replaceMeta.url;
      this.meta.referer = replaceMeta.referer;
      // end extractors
      API.fetchPinForExist(this.user.meta.username, searchMeta.url, searchMeta.referer).then(
        (resp) => {
          const { results } = resp.data;
          const pin = results.length > 0 ? results[0] : null;
          if (pin) {
            modals.openPinEdit(
              this,
              {
                username: this.user.meta.username,
                existedPin: Pins.createImageItem(pin),
                isEdit: true,
              },
              null,
              this.onUpdated,
              this.onClosed,
            );
          } else {
            modals.openPinEdit(
              this,
              {
                username: this.user.meta.username,
                fromUrl: this.meta,
              },
              this.onCreated,
              null,
              this.onClosed,
            );
          }
        },
        () => { window.close(); },
      );
    },
  },
  created() {
    this.meta = this.$route.query;
    this.initialize();
  },
};
</script>

<style scoped>

</style>
