import Extractor from './extractor';

// ruliweb referer clean.
class ExtractorRuliweb extends Extractor {
  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  hasDomain(meta) {
    return meta.referer.includes('ruliweb');
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getURL(url) {
    return url.replace('/img/', '/ori/');
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getReferer(referer) {
    const splits = referer.split('?');
    return splits[0];
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getSearchMeta(meta) {
    const temp = meta; // shallow copy.
    temp.url = this.getURL(meta.url);
    temp.referer = this.getReferer(meta.referer);
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getReplaceMeta(meta) {
    const temp = meta; // shallow copy.
    temp.url = this.getURL(meta.url);
    temp.referer = this.getReferer(meta.referer);
  }
}

export default ExtractorRuliweb;
