import Extractor from './extractor';

// validate url https://steamuserimages-a.akamaihd.net/ugc/0000000000000000000/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/?imw=1024&&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=false
// replace url https://steamuserimages-a.akamaihd.net/ugc/0000000000000000000/XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/
class ExtractorSteam extends Extractor {
  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  hasDomain(meta) {
    return meta.url.includes('steamuserimages');
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getURL(url) {
    const splits = url.split('/');
    return url.replace(splits[splits.length - 1], '');
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getSearchMeta(meta) {
    const temp = meta; // shallow copy.
    temp.url = this.getURL(meta.url);
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getReplaceMeta(meta) {
    const temp = meta; // shallow copy.
    temp.url = this.getURL(meta.url);
  }
}

export default ExtractorSteam;
