<template>
  <div class="search-panel">
    <div class="filter-selector">
      <div class="card-content">
        <b-field>
          <b-select v-bind:placeholder="$t('chooseFilterPlaceholder')" v-model="filterType">
            <option value="Tag">{{ $t("SearchPanelTagOption") }}</option>
            <option value="Board">{{ $t("SearchPanelBoardOption") }}</option>
          </b-select>
          <template v-if="filterType === 'Tag'">
            <b-taginput
              class="search-input"
              v-model="tagText"
              :data="filteredTagOptions"
              autocomplete
              ellipsis
              icon="label"
              :allow-new="true"
              v-bind:placeholder="$t('pinCreateModalImageTagsPlaceholder')"
              @typing="getFilteredTags">
              <template slot-scope="props">
                <strong>{{ props.option }}</strong>
              </template>
              <template slot="empty">
                {{ $t("pinCreateModalEmptySlot") }}
              </template>
            </b-taginput>
            <p class="control">
              <b-button @click="searchTags" class="button is-primary">{{ $t("searchButton") }}</b-button>
            </p>
          </template>
          <template v-if="filterType === 'Board'">
            <b-input
              class="search-input"
              type="search"
              v-model="boardText"
              v-bind:placeholder="$t('searchBoardPlaceholder')"
              icon="magnify"
            >
            </b-input>
            <p class="control">
              <b-button @click="searchBoard" class="button is-primary">{{ $t("searchButton") }}</b-button>
            </p>
          </template>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api';
import AutoComplete from '../utils/AutoComplete';

export default {
  name: 'FilterSelector',
  data() {
    return {
      filterType: null,
      tagText: [],
      boardText: '',
      selected: null,
      tagOptions: [],
      filteredTagOptions: [],
    };
  },
  methods: {
    fetchTagList() {
      api.Tag.fetchList().then(
        (resp) => {
          this.tagOptions = resp.data;
        },
      );
    },
    getFilteredTags(text) {
      const filteredTagOptions = [];
      AutoComplete.getFilteredOptions(
        this.tagOptions,
        text,
      ).forEach(
        (option) => {
          filteredTagOptions.push(option.name);
        },
      );
      this.filteredTagOptions = filteredTagOptions;
    },
    selectOption(filterName) {
      this.tagText = [];
      this.boardText = '';
      if (filterName === 'Tag') {
        this.filteredTagOptions = this.tagOptions;
      }
    },
    searchBoard() {
      if (this.boardText === '') {
        return;
      }
      this.$emit(
        'selected',
        { filterType: this.filterType, selected: this.boardText },
      );
    },
    searchTags() {
      if (this.tagText === []) {
        return;
      }
      this.$emit(
        'selected',
        { filterType: this.filterType, selected: this.tagText.join('&') },
      );
    },
  },
  watch: {
    filterType(newVal) {
      this.selectOption(newVal);
    },
  },
  created() {
    this.fetchTagList();
  },
};
</script>

<style scoped="scoped" lang="scss">
  .search-panel {
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .filter-selector {
    background-color: white;
    border-radius: 3px;
    .search-input {
      width: 100%;
    }
  }
</style>
