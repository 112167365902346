import Extractor from './extractor';

// deviantart is one referer. multi url. but one source image.
class ExtractorDeviantart extends Extractor {
  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  hasDomain(meta) {
    return meta.referer.includes('deviantart') || meta.referer.includes('sta.sh');
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getSearchMeta(meta) {
    const temp = meta; // shallow copy.
    temp.url = null;
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getReplaceMeta(meta) {

  }
}

export default ExtractorDeviantart;
