class Extractor {
  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  hasDomain(meta) {
    return true;
  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getSearchMeta(meta) {

  }

  // eslint-disable-next-line no-unused-vars, class-methods-use-this
  getReplaceMeta(meta) {

  }
}

export default Extractor;
