<template>
  <div class="pin-create-modal">
    <div>
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ $t(editorMeta.title) }}</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <FileUpload
                :previewImageURL="pinModel.form.url.value"
                v-on:imageUploadSucceed="onUploadDone"
                v-on:imageUploadProcessing="onUploadProcessing"
              ></FileUpload>
              <div class="description" v-show="pinModel.form.description.value" v-html="niceLinks(pinModel.form.description.value)"></div>
            </div>
            <div class="column">
              <b-field v-bind:label="$t('imageUrlLabel')"
                       v-show="!disableUrlField && !isEdit"
                       :type="pinModel.form.url.type"
                       :message="pinModel.form.url.error">
                <b-input
                  type="text"
                  v-model="pinModel.form.url.value"
                  v-bind:placeholder="$t('pinCreateModalImageURLPlaceholder')"
                  maxlength="2048"
                >
                </b-input>
              </b-field>
              <b-field v-bind:label="$t('privacyOptionLabel')"
                       :type="pinModel.form.private.type"
                       :message="pinModel.form.private.error">
                <b-checkbox v-model="pinModel.form.private.value">
                    {{ $t("isPrivateCheckbox") }}
                </b-checkbox>
              </b-field>
              <b-field v-bind:label="$t('imageSourceLabel')"
                       :type="pinModel.form.referer.type"
                       :message="pinModel.form.referer.error">
                <b-input
                  type="text"
                  v-model="pinModel.form.referer.value"
                  v-bind:placeholder="$t('pinCreateModalImageSourcePlaceholder')"
                  maxlength="2048"
                >
                </b-input>
              </b-field>
              <b-field v-bind:label="$t('tagsLabel')">
                <b-taginput
                    v-model="pinModel.form.tags.value"
                    :data="editorMeta.filteredTagOptions"
                    autocomplete
                    ellipsis
                    icon="label"
                    :allow-new="true"
                    v-bind:placeholder="$t('pinCreateModalImageTagsPlaceholder')"
                    @typing="getFilteredTags">
                  <template slot-scope="props">
                    <strong>{{ props.option }}</strong>
                  </template>
                  <template slot="empty">
                    {{ $t("pinCreateModalEmptySlot") }}
                  </template>
                </b-taginput>
              </b-field>
              <b-field>
                <button
                  v-if="!isEdit"
                  class="button"
                  type="button"
                  @click="setRecentlyTags">Set Recently Tags
                </button>
              </b-field>
              <b-field v-bind:label="$t('descriptionLabel')"
                       :type="pinModel.form.description.type"
                       :message="pinModel.form.description.error">
                <b-input
                  type="textarea"
                  v-model="pinModel.form.description.value"
                  v-bind:placeholder="$t('pinCreateModalImageDescriptionPlaceholder')"
                  maxlength="1024"
                >
                </b-input>
              </b-field>
            </div>
            <div class="column" v-if="!isEdit">
              <FilterSelect
                ref="filterSelect"
                :allOptions="boardOptions"
                v-on:selected="onSelectBoard"
              ></FilterSelect>
              <div class="column">
                <button
                  class="button"
                  type="button"
                  @click="setRecentlyBoards">Set Recently Boards
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="closePin">{{ $t("closeButton") }}
          </button>
          <button
            v-if="!isEdit"
            @click="createPin"
            class="button is-primary">{{ $t("pinCreateModalCreatePinButton") }}
          </button>
          <button
            v-if="!isEdit"
            @click="setRecentlyTagsAndBoards"
            class="button">Set Recently Tags And Boards
          </button>
          <button
            v-if="isEdit"
            @click="savePin"
            class="button is-primary">{{ $t("pinCreateModalSaveChangesButton") }}
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import API from '../api';
import FileUpload from './FileUpload.vue';
import FilterSelect from './FilterSelect.vue';
import bus from '../utils/bus';
import ModelForm from '../utils/ModelForm';
import Loading from '../utils/Loading';
import AutoComplete from '../utils/AutoComplete';
import niceLinks from '../utils/niceLinks';


function isURLBlank(url) {
  return url !== null && url === '';
}

const fields = ['url', 'referer', 'description', 'tags', 'private'];

export default {
  name: 'PinCreateModal',
  props: {
    fromUrl: {
      type: Object,
      default: null,
    },
    username: {
      type: String,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    existedPin: {
      type: Object,
      default: null,
    },
  },
  components: {
    FileUpload,
    FilterSelect,
  },
  data() {
    const pinModel = ModelForm.fromFields(fields);
    pinModel.form.tags.value = [];
    return {
      disableUrlField: false,
      pinModel,
      formUpload: {
        imageId: null,
      },
      boardIds: null,
      boardOptions: [],
      tagOptions: [],
      editorMeta: {
        title: 'NewPinTitle',
        filteredTagOptions: [],
      },
    };
  },
  created() {
    this.fetchBoardList();
    this.fetchTagList();
    if (this.isEdit) {
      this.editorMeta.title = 'EditPinTitle';
      this.pinModel.form.url.value = this.existedPin.url;
      this.pinModel.form.referer.value = this.existedPin.referer;
      this.pinModel.form.description.value = this.existedPin.description;
      this.pinModel.form.tags.value = this.existedPin.tags;
      this.pinModel.form.private.value = this.existedPin.private;
    } else {
      this.pinModel.form.private.value = this.getRecentlyPrivate();
    }
    if (this.fromUrl) {
      this.pinModel.form.url.value = this.fromUrl.url;
      this.pinModel.form.referer.value = this.fromUrl.referer;
      this.pinModel.form.description.value = this.fromUrl.description;
    }
  },
  methods: {
    fetchTagList() {
      API.Tag.fetchList().then(
        (resp) => {
          this.tagOptions = resp.data;
        },
      );
    },
    getFilteredTags(text) {
      const filteredTagOptions = [];
      AutoComplete.getFilteredOptions(
        this.tagOptions,
        text,
      ).forEach(
        (option) => {
          filteredTagOptions.push(option.name);
        },
      );
      this.editorMeta.filteredTagOptions = filteredTagOptions;
    },
    fetchBoardList() {
      API.Board.fetchFullList(this.username).then(
        (resp) => {
          const boardOptions = [];
          resp.data.forEach(
            (board) => {
              const boardOption = { name: board.name, value: board.id };
              boardOptions.push(boardOption);
            },
          );
          this.boardOptions = boardOptions;
        },
        () => {
          console.log('Error occurs while fetch board full list');
        },
      );
    },
    onSelectBoard(boardIds) {
      this.boardIds = boardIds;
    },
    onUploadProcessing() {
      this.disableUrlField = true;
    },
    onUploadDone(imageId) {
      this.formUpload.imageId = imageId;
    },
    saveCookies() {
      if (this.isEdit) {
        return;
      }
      const cUserName = this.username;
      const cPrivate = this.pinModel.form.private.value;
      const cTags = JSON.stringify(this.pinModel.form.tags.value);
      const cBoards = JSON.stringify(this.boardIds);
      this.$cookies.set('pinry-recently-username', cUserName);
      this.$cookies.set('pinry-recently-pin-private', cPrivate);
      this.$cookies.set('pinry-recently-pin-tags', cTags);
      this.$cookies.set('pinry-recently-pin-boards', cBoards);
      console.log('Save Cookies');
    },
    isValidRecentlyUser() {
      if (this.$cookies.isKey('pinry-recently-username')) {
        const cUserName = this.$cookies.get('pinry-recently-username');
        return cUserName === this.username;
      }
      return false;
    },
    getRecentlyPrivate() {
      if (this.$cookies.isKey('pinry-recently-pin-private')) {
        const cPrivate = this.$cookies.get('pinry-recently-pin-private');
        return cPrivate;
      }
      return true;
    },
    getRecentlyTags() {
      if (this.$cookies.isKey('pinry-recently-pin-tags') && this.isValidRecentlyUser()) {
        const cTags = this.$cookies.get('pinry-recently-pin-tags');
        return cTags;
      }
      return [];
    },
    getRecentlyBoards() {
      if (this.$cookies.isKey('pinry-recently-pin-boards') && this.isValidRecentlyUser()) {
        const cBoards = this.$cookies.get('pinry-recently-pin-boards');
        return cBoards;
      }
      return null;
    },
    setRecentlyTags() {
      this.pinModel.form.tags.value = this.getRecentlyTags();
    },
    setRecentlyBoards() {
      this.$refs.filterSelect.setSelect(this.getRecentlyBoards());
    },
    setRecentlyTagsAndBoards() {
      this.setRecentlyTags();
      this.setRecentlyBoards();
    },
    closePin() {
      const self = this;
      self.$emit('pinClosed');
      self.$parent.close();
    },
    savePin() {
      const self = this;
      const data = this.pinModel.asDataByFields(
        ['referer', 'description', 'tags', 'private'],
      );
      const promise = API.Pin.updateById(this.existedPin.id, data);
      promise.then(
        (resp) => {
          bus.bus.$emit(bus.events.refreshPin);
          self.$emit('pinUpdated', resp);
          self.$parent.close();
        },
      );
    },
    createPin() {
      const loading = Loading.open(this);
      const self = this;
      let promise;
      if (isURLBlank(this.pinModel.form.url.value) && this.formUpload.imageId === null) {
        return;
      }
      if (this.formUpload.imageId === null) {
        const data = this.pinModel.asDataByFields(fields);
        promise = API.Pin.createFromURL(data);
      } else {
        const data = this.pinModel.asDataByFields(
          ['referer', 'description', 'tags', 'private'],
        );
        data.image_by_id = this.formUpload.imageId;
        promise = API.Pin.createFromUploaded(data);
      }
      this.saveCookies();
      promise.then(
        (resp) => {
          const promises = [];
          function done() {
            self.$emit('pinCreated', resp);
            self.$parent.close();
            loading.close();
          }
          bus.bus.$emit(bus.events.refreshPin);
          if (self.boardIds) {
            // FIXME(winkidney): Should handle error for add-to board
            self.boardIds.forEach(
              (boardId) => {
                promises.push(API.Board.addToBoard(boardId, [resp.data.id]));
              },
            );
          }
          if (promises.length > 0) {
            axios.all(promises).then(done);
          } else {
            done();
          }
        },
      ).catch((error) => {
        console.log('Cannot create pin:', error);
        loading.close();
      });
    },
    niceLinks,
  },
};
</script>
