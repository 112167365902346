import ExtractorDeviantart from './deviantart';
import ExtractorRuliweb from './ruliweb';
import ExtractorSteam from './steam';

const extractors = [
  new ExtractorSteam(),
  new ExtractorDeviantart(),
  new ExtractorRuliweb(),
];

export default {
  extractor(meta) {
    const searchMeta = { url: meta.url, referer: meta.referer };
    const replaceMeta = { url: meta.url, referer: meta.referer };
    extractors.forEach((extractor) => {
      if (extractor.hasDomain(meta)) {
        extractor.getSearchMeta(searchMeta);
        extractor.getReplaceMeta(replaceMeta);
        return true;
      }
      return false;
    });
    return { searchMeta, replaceMeta };
  },
};
